import { useReactiveVar } from '@apollo/client';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { GrClose } from 'react-icons/gr';
import {
  IBetLimit,
  IBetLimitPost,
  TABLE_BETLIMIT_MOD,
  USER_TLIMIT_MOD,
} from '../../../../../common/api/mutations/betlimit_mutation';
import { isUpdatedVar, limitData } from '../../../../../common/apollo';
import { ButtonCustom } from '../../../../../components/button';
import { useTranslation } from 'react-i18next';

interface IModalProps {
  setModModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TableBetLimitModModal: React.FC<IModalProps> = ({
  setModModal,
}) => {
  const { register, getValues, handleSubmit, control } = useForm<IBetLimit>({
    mode: 'onChange',
  });
  const { t } = useTranslation(['page']);
  // const { isSubmitting } = useFormState({ control });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const selectedLimitData = useReactiveVar(limitData);
  const isUpdated = useReactiveVar(isUpdatedVar);

  const clickModalClose = () => {
    setModModal((current) => !current);
  };
  const onSubmit = () => {
    // Still duplicates request even with states. Added this to make sure.
    if (isButtonDisabled) return;
    setIsButtonDisabled(true);
    const {
      name,
      ut_bp_min,
      bp_min,
      bp_max,
      // ut_pair_min,
      // ut_tie_min,
      pair_min,
      pair_max,
      tie_min,
      tie_max,
      super6_min,
      super6_max,
    } = getValues();

    const tLData = {
      id: selectedLimitData?.id,
      name: selectedLimitData?.name, // Send Default Name for the meantime
      currency: '',
      bp_min: bp_min,
      bp_max: bp_max,
      pair_min: pair_min,
      pair_max: pair_max,
      tie_min: tie_min,
      tie_max: tie_max,
      super6_min: super6_min,
      super6_max: super6_max,
    };

    const uTData = {
      id: selectedLimitData?.id,
      name: selectedLimitData?.name,
      currency: '',
      bp_min: ut_bp_min,
      bp_max: bp_max,
      pair_min: pair_min,
      pair_max: pair_max,
      tie_min: tie_min,
      tie_max: tie_max,
      super6_min: super6_min,
      super6_max: super6_max,
    };
    TABLE_BETLIMIT_MOD(tLData)
      .then((res: AxiosResponse | any) => {
        let transactionResult = 0;
        if (res.data.data[0].code === 1) {
          transactionResult = 1;
        }
        return transactionResult;
      })
      .then((transactionResult) => {
        if (transactionResult !== 1) {
          alert('Registration Failed');
        } else {
          USER_TLIMIT_MOD(uTData)
            .then((res: AxiosResponse | any) => {
              if (res.data.data[0].code === 1) {
                alert('Registration Success');
                isUpdatedVar(!isUpdated);
              } else if (res.data.data[0].code === 0) {
                alert('Registration Failed');
                isUpdatedVar(!isUpdated);
              }
            })
            .catch((err) => {
              alert('Registration Failed');
              console.log(err);
            });
        }
      })
      .catch((err) => {
        alert('Registration Failed');
        console.log(err);
      })
      .finally(() => {
        setIsButtonDisabled(false);
      });
  };

  return (
    <div className='box-border fixed top-0 bottom-0 left-0 right-0 z-10 overflow-auto outline-0'>
      <div className='fixed w-full h-full backdrop-blur-sm'>
        <div className='box-border relative m-24 bg-inherit top-12'>
          <div className='flex'>
            <div className='mx-auto border rounded-md shadow-md lg:col-span-2'>
              <div className='flex flex-row justify-between px-6 py-3 text-lg font-medium bg-white border-b'>
                <div className='text-blue-500'>한도 수정</div>
                <GrClose
                  className='mt-1 cursor-pointer'
                  onClick={clickModalClose}
                />
              </div>
              <div className='p-6 bg-white shadow-lg'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {/* <input
                    type='text'
                    name='username'
                    className='block w-0 h-0 border-0'
                  />
                  <input type='password' className='block w-0 h-0 border-0' /> */}
                  <div className='grid grid-cols-2 gap-6 mb-6'>
                    <div className='form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('Limit Name')}
                        </div>
                      </div>
                      <input
                        {...register('name')}
                        disabled
                        minLength={5}
                        maxLength={15}
                        type='text'
                        className='bg-gray-100 forminput'
                        defaultValue={selectedLimitData?.name}
                        placeholder='Enter Account'
                      />
                    </div>
                    <div className='form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          유저 {t('B.P Minimum')}
                        </div>
                      </div>
                      <input
                        {...register('ut_bp_min')}
                        type='number'
                        className='forminput'
                        defaultValue={selectedLimitData?.ut_bp_min}
                        placeholder='BP Minimum Input'
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-2 gap-6 mb-8'>
                    <div className='form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('B.P Minimum')}
                        </div>
                      </div>
                      <input
                        {...register('bp_min')}
                        type='number'
                        className='forminput'
                        defaultValue={selectedLimitData?.bp_min}
                        placeholder='BP Minimum Input'
                      />
                    </div>
                    <div className='form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>{t('B.P Max')}</div>
                      </div>
                      <input
                        {...register('bp_max')}
                        type='number'
                        className='forminput'
                        defaultValue={selectedLimitData?.bp_max}
                        placeholder='BP Max Input'
                      />
                    </div>
                    {/* <div className='form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>유저 {t('Pair Minimum')}</div>
                      </div>
                      <input
                        {...register('ut_pair_min')}
                        type='number'
                        className='forminput'
                        defaultValue={selectedLimitData?.ut_pair_min}
                        placeholder='Pair Minimum Input'
                      />
                    </div> */}
                    <div className='form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('Pair Minimum')}
                        </div>
                      </div>
                      <input
                        {...register('pair_min')}
                        type='number'
                        className='forminput'
                        defaultValue={selectedLimitData?.pair_min}
                        placeholder='Pair Minimum Input'
                      />
                    </div>
                    <div className='form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('Pair Max')}
                        </div>
                      </div>
                      <input
                        {...register('pair_max')}
                        type='number'
                        className='forminput'
                        defaultValue={selectedLimitData?.pair_max}
                        placeholder='Pair Max Input'
                      />
                    </div>
                    {/* <div className='form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>유저 {t('Tie Minimum')}</div>
                      </div>
                      <input
                        {...register('ut_tie_min')}
                        type='number'
                        className='forminput'
                        defaultValue={selectedLimitData?.ut_tie_min}
                        placeholder='Tie Minimum Input'
                      />
                    </div> */}
                    <div className='form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('Tie Minimum')}
                        </div>
                      </div>
                      <input
                        {...register('tie_min')}
                        type='number'
                        className='forminput'
                        defaultValue={selectedLimitData?.tie_min}
                        placeholder='Tie Minimum Input'
                      />
                    </div>
                    <div className='form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>{t('Tie Max')}</div>
                      </div>
                      <input
                        {...register('tie_max')}
                        type='number'
                        className='forminput'
                        defaultValue={selectedLimitData?.tie_max}
                        placeholder='Tie Max Input'
                      />
                    </div>
                    <div className=' form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('Super 6 Minimum')}
                        </div>
                      </div>
                      <input
                        {...register('super6_min')}
                        type='number'
                        defaultValue={selectedLimitData?.super6_min}
                        className='forminput'
                        placeholder='Super 6 Minimum Input'
                      />
                    </div>
                    <div className='form-group'>
                      <div className='flex flex-row'>
                        <div className='mb-2 text-gray-500'>
                          {t('Super 6 Max')}
                        </div>
                      </div>
                      <input
                        {...register('super6_max')}
                        type='number'
                        defaultValue={selectedLimitData?.super6_max}
                        className='forminput'
                        placeholder='Super 6 Max Input'
                      />
                    </div>
                  </div>
                  <ButtonCustom
                    canClick={!isButtonDisabled}
                    actionText={'Modify Table Limit'}
                  />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
