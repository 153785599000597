import BPPair from '../../common/assets/betpair_dot/banker_lowdot.png';
import BetBPPair from '../../common/assets/betpair_dot/BankerPair.png';
import BBPair from '../../common/assets/betpair_dot/banker_updot.png';
import BDPair from '../../common/assets/betpair_dot/banker_dw.png';
// import BNPair from '../../common/assets/betpair_dot/banker_nodot.png';
import BNPair from '../../common/assets/betpair_dot/Banker.png';
import BBonus from '../../common/assets/betpair_dot/banker_bonus_nodot.png';

import PPPair from '../../common/assets/betpair_dot/player_lowdot.png';
import BetPPPair from '../../common/assets/betpair_dot/PlayerPair.png';
import PBPair from '../../common/assets/betpair_dot/player_updot.png';
import PDPair from '../../common/assets/betpair_dot/player_dw.png';
// import PNPair from '../../common/assets/betpair_dot/player_nodot.png';
import PNPair from '../../common/assets/betpair_dot/Player.png';
import PBonus from '../../common/assets/betpair_dot/player_bonus_nodot.png';

import TPPair from '../../common/assets/betpair_dot/tie_lowdot.png';
import TBPair from '../../common/assets/betpair_dot/tie_updot.png';
import TDPair from '../../common/assets/betpair_dot/tie_dw.png';
// import TNPair from '../../common/assets/betpair_dot/tie_nodot.png';
import TNPair from '../../common/assets/betpair_dot/Tie.png';

import Big from '../../common/assets/betpair_dot/Big.png';
import ETPair from '../../common/assets/betpair_dot/EitherPair.png';
import L6 from '../../common/assets/betpair_dot/Lucky6.png';
import PFPair from '../../common/assets/betpair_dot/PerfectPair.png';
import Small from '../../common/assets/betpair_dot/Small.png';
import S6 from '../../common/assets/betpair_dot/Super6.png';

export const cardListImage = {
  BPPair,
  BetBPPair,
  BBPair,
  BDPair,
  BNPair,
  BBonus,

  PPPair,
  BetPPPair,
  PBPair,
  PDPair,
  PNPair,
  PBonus,

  TPPair,
  TBPair,
  TDPair,
  TNPair,

  Big,
  ETPair,
  L6,
  PFPair,
  Small,
  S6, // Not sure
};

export const betImageMapping: Record<string, any> = {
  B10: cardListImage.BPPair,
  B01: cardListImage.BBPair,
  B11: cardListImage.BDPair,
  B00: cardListImage.BNPair,

  P10: cardListImage.PPPair,
  P01: cardListImage.PBPair,
  P11: cardListImage.PDPair,
  P00: cardListImage.PNPair,

  T10: cardListImage.TPPair,
  T01: cardListImage.TBPair,
  T11: cardListImage.TDPair,
  T00: cardListImage.TNPair,

  P: cardListImage.PNPair,
  PB: cardListImage.PBonus,
  B: cardListImage.BNPair,
  BB: cardListImage.BBonus,
  T: cardListImage.TNPair,
  PP: cardListImage.BetPPPair,
  BP: cardListImage.BetBPPair,
  'P.P': cardListImage.PPPair,
  'B.P': cardListImage.BPPair,
  BI: cardListImage.Big,
  ET: cardListImage.ETPair,
  L6: cardListImage.L6,
  PF: cardListImage.PFPair,
  SM: cardListImage.Small,
  S6: cardListImage.S6,
};
